import React from 'react';
import Logo from '../static/playstore.svg';

const PlayStore = ({ href, className }) => (
  <a href={href} className={className} target="_blank" rel="noopener noreferrer">
    <img draggable="false" className="select-none w-40" src={Logo} alt="Get it on Google Play" />
  </a>
);

export default PlayStore;
