import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../components/Button';
import Card from '../components/Card';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import MobileGraph from '../svg/MobileGraph';
import TeamGraph from '../svg/TeamGraph';
import Technologies from '../components/Technologies';
import References from '../components/References';
import CopyIcon from '../svg/CopyIcon';

const mail = 'hello@develooped.com';
const copyToClipboard = text => navigator.clipboard.writeText(text);

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40" id="top">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Develooped</h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            We deliver web applications satisfying all your unique business goals. Trust in our
            expertise and enthusiasm to find the best solution for your company.
          </p>
          <AnchorLink href="#form" style={{ color: 'inherit' }}>
            <p className="mt-8 md:mt-12">
              <Button size="lg">Get in touch</Button>
            </p>
          </AnchorLink>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Services</h2>
        <div className="flex flex-col md:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Web application development</p>
              <p className="mt-4">
                Combine the pixel-perfect look with stable and effortless management.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Mobile development</p>
              <p className="mt-4">
                Create an impactful mobile app that fits all the needs of your busines.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Dedicated engineer team</p>
              <p className="mt-4">Hire a software development team with deep expertise.</p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Unique web applications</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We provide both back-end and front-end development. Our teams build web applications
            powered by bulletproof code, with stunning interfaces based on responsive web design.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Mobile applications</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Smartphones, tablets, wearables - people use them more and more. Reach the most
            customers via cross-platform solutions.
          </p>
        </div>
      }
      secondarySlot={<MobileGraph />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Dedicated team</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            In case you need a few extra developers for your product, we provide enthusiastic teams.
            They understand You, Eachother and the Problem to be solved.
          </p>
        </div>
      }
      secondarySlot={<TeamGraph />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="6" secondaryText="Delivered Projects" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="Of Our Clients Recommends us" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="2" secondaryText="Teams" />
          </div>
        </div>
      </div>
    </section>

    <Technologies
      technologies={[
        'react',
        'graphql',
        'expo',
        'npm',
        'node',
        'svelte',
        'css3',
        'electron',
        'typescript',
        'docker',
        'jest',
        'next'
      ]}
    />

    <References />

    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center" id="form">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8">
        <a href={`mailto:${mail}`}>
          <Button size="xl" title="Open default mail client">
            Make it happen
          </Button>
        </a>
      </p>
      <div className="mt-6 bg-primary font-medium hover:bg-primary-darker cursor-pointer w-1/4 mx-auto py-5 flex justify-evenly text-white rounded">
        <p>{mail}</p>
        <i
          title="Copy email to clipboard"
          className="w-5 h-5 inline-block"
          tabIndex="0"
          aria-label="Copy email clipboard"
          role="button"
          onPointerDown={() => copyToClipboard(mail)}
          onKeyDown={() => {
            copyToClipboard(mail);
          }}
        >
          <CopyIcon />
        </i>
      </div>
    </section>
  </Layout>
);
