import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.freepik.com/free-photos-vectors/technology"
        >
          Artworks created by slidesgo
        </a>
        <p className="mt-5">Develooped Software Kft. © 2020</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Find us</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/Develooped/"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/12628664"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
