import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charset="UTF-8" />
        <title>Software engineering - Develooped</title>
        <meta
          name="description"
          content="Develooped Software Kft. is an IT company in Budapest, Hungary. Its main services are making unique web applications, mobile apps. It also provides dedicated developer teams"
        />
        <meta name="keywords" content="webapplication, software, mobile, iot, developer, team" />
        <meta name="author" content="Develooped Software Kft." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
