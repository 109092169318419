import React from 'react';

import Pictures from '../static/technologies/TechnologyImages';

export default function Technologies({ technologies }) {
  return (
    <section id="technologies" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl lg:text-4xl font-semibold">Technologies we use</h2>
        <div className="flex flex-row mt-6 lg:px-24 flex-wrap">
          {technologies.map(technology => (
            <a
              key={technology}
              href={Pictures[technology].url}
              className="block w-1/4 md:w-1/6 p-6 md:p-10"
              draggable="false"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                draggable="false"
                className="transition duration-700 ease-in-out gray opacity-50 hover:opacity-100 select-none"
                src={Pictures[technology].image}
                alt={technology}
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}
