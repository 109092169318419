import React from 'react';
import PlayStore from '../svg/PlayStore';
import AppStore from '../svg/AppStore';
import Ulockme from '../static/references/ulockme.png';
import Comity from '../static/references/comity.png';
import Atmosplay from '../static/references/atmosplay.png';
import Codinglab from '../static/references/codinglab.png';

const references = [
  {
    title: 'Ulockme mobile app',
    description:
      'Our team took part in developing iOs and Android applications of Ulockme keyless gate opener.',
    image: Ulockme,
    playstore: 'https://play.google.com/store/apps/details?id=com.ulockme&hl=en',
    appstore: 'https://apps.apple.com/hu/app/ulockme-home/id1115167255?l=en',
    website: 'http://www.ulockme.hu/'
  },
  {
    title: 'Atmosplay web application',
    description: "Our team helps engineering Atmosplay's advertisement creating products.",
    image: Atmosplay,
    playstore: '',
    appstore: '',
    website: 'https://atmosplay.com/'
  },
  {
    title: 'CodingLab',
    description:
      'We have collaborated on multiple web-based projects with CodingLab, producing complex systems for several startups and organizations.',
    image: Codinglab,
    playstore: '',
    appstore: '',
    website: 'https://codinglab.hu/en/'
  },
  {
    title: 'Employer branding portal with CMS',
    description:
      'Comity.hu is a highly interactive and fast web application, full of media, where anyone can find their ideal workspace. The content is managed by our admin system.',
    image: Comity,
    playstore: '',
    appstore: '',
    website: 'https://comity.hu/'
  }
];

function References() {
  return (
    <section id="references" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl lg:text-4xl font-semibold">Our references</h2>
        <div className="flex sm:flex-column md:flex-row mt-6 lg:px-24 flex-wrap">
          {references.map(reference => (
            <div className="w-full md:w-1/2 p-8 flex-column items-center">
              <h3 className="text-xl lg:text-2xl font-semibold pb-4">
                <a
                  href={reference.website}
                  className="text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {reference.title}
                </a>
              </h3>
              {reference.image && (
                <img
                  src={reference.image}
                  draggable="false"
                  className="select-none max-h-3/4 mx-auto"
                  alt={reference.title}
                />
              )}
              {reference.description && (
                <p className="text-gray-600 py-2">{reference.description}</p>
              )}
              {reference.playstore && (
                <PlayStore
                  className="flex justify-center"
                  href="https://play.google.com/store/apps/details?id=com.ulockme&hl=en"
                />
              )}
              {reference.appstore && (
                <AppStore
                  className="flex justify-center"
                  href="https://apps.apple.com/hu/app/ulockme-home/id1115167255?l=en"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default References;
