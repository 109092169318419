import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="md:sticky top-0 bg-white shadow z-10" id="header">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <AnchorLink href="#top" style={{ color: 'inherit' }}>
        <div className="flex items-center text-2xl" style={{ textDecoration: 'none' }}>
          <div className="w-12 mr-3">
            <LogoIcon />
          </div>
          Develooped
        </div>
      </AnchorLink>
      <div className="flex flex-col sm:mt-0 md:flex-row">
        <AnchorLink className="px-4" href="#features">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Stats
        </AnchorLink>
        <AnchorLink className="px-4" href="#references">
          References
        </AnchorLink>
        <AnchorLink className="px-4" href="#technologies">
          Technologies
        </AnchorLink>
      </div>
      <AnchorLink href="#form" style={{ color: 'inherit' }}>
        <div className="hidden md:block">
          <Button className="text-sm">Contact</Button>
        </div>
      </AnchorLink>
    </div>
  </header>
);

export default Header;
