import amazonaws from './amazonaws.svg';
import css3 from './css3.svg';
import electron from './electron.svg';
import expo from './expo.svg';
import gatsby from './gatsby.svg';
import git from './git.svg';
import graphql from './graphql.svg';
import javascript from './javascript.svg';
import jest from './jest.svg';
import json from './json.svg';
import kubernetes from './kubernetes.svg';
import next from './next.svg';
import node from './node.svg';
import npm from './npm.svg';
import php from './php.svg';
import react from './react.svg';
import redis from './redis.svg';
import sass from './sass.svg';
import svelte from './svelte.svg';
import typescript from './typescript.svg';
import vue from './vue.svg';
import docker from './docker.svg';

export default {
  amazonaws: {
    url: 'https://aws.amazon.com/',
    image: amazonaws
  },
  css3: {
    url: 'https://www.w3.org/Style/CSS/specs.en.html',
    image: css3
  },
  electron: {
    url: 'https://www.electronjs.org/',
    image: electron
  },
  expo: {
    url: 'https://expo.io/',
    image: expo
  },
  gatsby: {
    url: 'https://www.gatsbyjs.org/',
    image: gatsby
  },
  git: {
    url: 'https://git-scm.com/',
    image: git
  },
  graphql: {
    url: 'https://graphql.org/',
    image: graphql
  },
  javascript: {
    url: 'https://www.ecma-international.org/publications/standards/Ecma-262.htm',
    image: javascript
  },
  jest: {
    url: 'https://jestjs.io/',
    image: jest
  },
  json: {
    url: 'https://www.json.org/',
    image: json
  },
  kubernetes: {
    url: 'https://kubernetes.io/',
    image: kubernetes
  },
  next: {
    url: 'https://nextjs.org/',
    image: next
  },
  node: {
    url: 'https://nodejs.org/',
    image: node
  },
  npm: {
    url: 'https://www.npmjs.com/',
    image: npm
  },
  php: {
    url: 'https://www.php.net/',
    image: php
  },
  docker: {
    url: 'https://www.docker.com/',
    image: docker
  },
  react: {
    url: 'https://reactjs.org/',
    image: react
  },
  redis: {
    url: 'https://redis.io/',
    image: redis
  },
  sass: {
    url: 'https://sass-lang.com/',
    image: sass
  },
  svelte: {
    url: 'https://svelte.dev/',
    image: svelte
  },
  typescript: {
    url: 'https://www.typescriptlang.org/',
    image: typescript
  },
  vue: {
    url: 'https://vuejs.org/',
    image: vue
  }
};
